
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component({
		name: 'PixcapLogo',
	})
	export default class PixcapLogo extends Vue {
		@Prop({ default: false }) readonly isWhite: boolean;

		uniqueIdentifier = new Date().getTime();
		clipPathId = `clip-path-${this.uniqueIdentifier}`;
		paint0Id = `paint0_linear_${this.uniqueIdentifier}`;
		paint1Id = `paint1_linear_${this.uniqueIdentifier}`;
		paint2Id = `paint2_linear_${this.uniqueIdentifier}`;
		paint3Id = `paint3_linear_${this.uniqueIdentifier}`;
		paint4Id = `paint4_linear_${this.uniqueIdentifier}`;
	}
