import { render, staticRenderFns } from "./TypographyText.vue?vue&type=template&id=6c623522&scoped=true&"
import script from "./TypographyText.vue?vue&type=script&lang=ts&"
export * from "./TypographyText.vue?vue&type=script&lang=ts&"
import style0 from "./TypographyText.vue?vue&type=style&index=0&id=6c623522&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c623522",
  null
  
)

export default component.exports