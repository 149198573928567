import { Component, Vue, Prop } from 'vue-property-decorator';
import { TextWeight, TextCapitalization } from '@pixcap/ui-library/common/typography/typography';

@Component({
	name: 'TextMixin',
})
export default class TextMixin extends Vue {
	@Prop() readonly className: string | undefined;
	@Prop() readonly tagName: string | undefined;
	@Prop({ default: 'normal' }) readonly weight: TextWeight;
	@Prop({ default: 'default' }) readonly capitalization: TextCapitalization | undefined;
}
