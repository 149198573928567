
	import { Component, Prop, Vue } from 'vue-property-decorator';

	import { TextWeight, TextSize, TextType, TextCapitalization } from '@pixcap/ui-library/common/typography/typography';

	@Component({
		name: 'TypographyText',
	})
	export default class TypographyText extends Vue {
		@Prop() readonly tagName: string;
		@Prop() readonly className: string | undefined;
		@Prop() readonly weight: TextWeight;
		@Prop() readonly size: TextSize;
		@Prop() readonly type: TextType;
		@Prop() readonly capitalization: TextCapitalization;

		get computedClassName() {
			return [
				`text--${this.type}--${this.size}`,
				`text--weight--${this.weight}`,
				this.capitalization === 'uppercase' && 'text-uppercase',
				this.className ? this.className : '',
			];
		}
	}
